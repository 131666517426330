export const AUTHENTICATION_URLS = {
  ACCESS_TOKEN: '/api/access-token',
  REFRESH_TOKEN: '/api/refresh-token'
};

export const ADMIN_URLS = {
  INFO: '/api/admin/info',
  UPDATE_PASSWORD: '/api/admin/update-password',
  PRIZES_RETRIEVE: '/api/admin/prizes/retrieve',
  PRIZE_UPDATE: '/api/admin/prizes/info',
  DISTRIBUTION_UPDATE: '/api/admin/prizes/distribution',
  EXPORT_TICKETS: '/api/admin/export/tickets',
  IMPORT_TICKETS: '/api/admin/import/tickets',
  EXPORT_CUSTOMERS: '/api/admin/export/customers',
  IMPORT_HOLOGRAM_CODES: '/api/admin/import/hologram-codes',
  RENEW_TICKETS: '/api/admin/renew/tickets',
  UPDATE_SYSTEM_SETTING: '/api/admin/system-setting'
};

export const CUSTOMER_URLS = {
  INDEX: '/api/customer',
  PRIZES: '/api/customer/prizes',
  CHECK_PROGRAM_ENABLED: '/api/customer/check-program-enabled',
  TICKET_CHECK: '/api/customer/ticket/check',
  TICKET_LINK: '/api/customer/ticket/link',
  ROTATE: '/api/customer/rotate',
  ADDRESS_TYPES: '/api/customer/address-types',
  PROVINCES: '/api/customer/provinces',
  DISTRICTS: '/api/customer/districts',
  WARDS: '/api/customer/wards',
  PARAMS: '/api/customer/params',
  GET_SYSTEM_SETTING: '/api/customer/system-setting',
  TICKET_TYPE: '/api/customer/ticket-type'
};

export const RESOURCE_URLS = {
  GET: '/api/resource',
};
